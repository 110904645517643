import React, { useEffect } from "react";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";

import FormWrapper from "../FormWrapper";
import FormGroup from "../FormGroup";
import { Field, FieldGroup } from "components/Design/Input/Field";
import Input from "components/Design/Input/Input";
import Textarea from "components/Design/Input/Textarea";
import Checkbox from "components/Design/Input/Checkbox";
import { ContactInformation } from "../CommonField";
import { JA_INQUIRY_PLACEHOLDER } from "store/constants/formPlaceholder";
import { AYANA_LAKODIA_ID } from "store/constants/hotel";

const GeneralInquiry = (hotels) => {
  const { language } = useI18next();
  const { t } = useTranslation("support");

  const titleOption = [
    { label: t("Mr"), value: "Mr" },
    { label: t("Ms"), value: "Ms" },
  ];

  return (
    <FormWrapper
      title={
        hotels?.hotels?.id === AYANA_LAKODIA_ID
          ? t("Cruise Inquiry")
          : t("General Inquiry")
      }>
      <FormGroup>
        <Field name="subject" label={t("Subject")}>
          <Input />
        </Field>

        <Field name="inquiry" label={t("Inquiry")}>
          <Textarea
            className={language === "ja" && "placeholder:pt-16"}
            placeholder={language === "ja" && JA_INQUIRY_PLACEHOLDER}
          />
        </Field>

        <FieldGroup name="promotional">
          <Checkbox type="checkbox">{t("receiveNewsletters")}</Checkbox>
        </FieldGroup>
      </FormGroup>

      <ContactInformation t={t} titleOption={titleOption} />
    </FormWrapper>
  );
};

export default GeneralInquiry;
